import { NFT } from "../types"

export function sumIngredients( recipe: NFT[] ) {
  const result = new Map<string, number>(); // <template_id, quantity>
  for ( const item of recipe ) {
    const key = [item.collection_name, item.template_id, item.schema_name].join(",");
    const previous = result.get( key ) || 0;
    result.set( key, previous + 1 );
  }
  return result;
};

export function parseRecipes( recipes: NFT[][] ) {
  const template_ids = new Map<string, [number, number]>(); // <collection_name,template_id, [count, quantity]>
  for ( const recipe of recipes ) {
    for ( const [ key, quantity ] of sumIngredients(recipe) ) {
      const previous = template_ids.get( key ) || [0];
      template_ids.set( key, [previous[0] + 1, quantity] );
    }
  }
  const fixed = new Map<string, number>(); // <collection_name,template_id, quantity>
  const dynamic = new Map<string, number>(); // <collection_name,template_id, quantity>
  for ( const [ template_id, [ count, quantity ]] of template_ids ) {
    if ( count === recipes.length ) fixed.set( template_id, quantity )
    else dynamic.set( template_id, quantity )
  }
  return [ fixed, dynamic ];
}

// const recipe1 = [{ collection_name: "pomelo", schema_name: "schema", template_id: 1 }, { collection_name: "pomelo",schema_name: "schema", template_id: 1 }, { collection_name: "pomelo", schema_name: "schema", template_id: 2 }]
// const recipe2 = [{ collection_name: "pomelo", schema_name: "schema", template_id: 1 }, { collection_name: "pomelo",schema_name: "schema", template_id: 1 }, { collection_name: "pomelo", schema_name: "schema", template_id: 3 }]

// const recipe3 = [1,2,3].map(() => { return { collection_name: "pomelo", schema_name: "schema", template_id: 1 }});
// const recipe4 = [1,2,3,4,5,6].map(() => { return { collection_name: "pomelo", schema_name: "schema", template_id: 1 }});
// console.log(parseRecipes([ recipe3, recipe4 ]));