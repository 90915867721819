import { AppBar, Toolbar } from '@material-ui/core';
import { useNavigate } from "react-router-dom";
import ConnectWallet from '../components/ConnectWallet';
import BlendDotgemsLogo from '../assets/blend-dotgems.png';
import DotgemsLogo from '../assets/dotgems-logo.png';
import { useStyles } from "../styles"

const Navbar = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    return (
        <AppBar
            position="static" className={classes.root} elevation={2}
            style={{ background: "linear-gradient(to right, rgb(51 48 211), rgba(100, 31, 233, 0.48))", color: "#003"}}
        >
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{display: "flex", cursor:"pointer"}} onClick={()=> navigate("")}>
                <img src={BlendDotgemsLogo} style={{ height: "52px", cursor:"pointer"}} onClick={() => navigate("") } />
                <img src={DotgemsLogo} style={{ height: "52px", filter: "brightness(1.35)"}}/>
            </div>
            <div style={{ display: "flex" }}>
                {/* <ChainSelect /> */}
                <ConnectWallet />
            </div>
        </Toolbar>
        </AppBar>
    )
}

export default Navbar;
