import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import reportWebVitals from './reportWebVitals';
import App from './App';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
// import MaintenancePage from './pages/MaintenancePage/MaintenancePage';

ReactDOM.render(
  <RecoilRoot>
    <BrowserRouter>
      <SnackbarProvider maxSnack={5} style={{ marginBottom: "20px" }}>
        <App />
        {/* <MaintenancePage/> */}
      </SnackbarProvider>
    </BrowserRouter>
  </RecoilRoot>,
  document.getElementById('root')
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
