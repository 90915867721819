import { atom } from 'recoil';
import { AtomicAsset } from '../types/atomic';

export const ownerState = atom({
    key: 'SET_OWNER_STATE',
    default: String(localStorage.getItem("owner") || ""),
});

export const balanceState = atom({
    key: "SET_BALANCE_STATE",
    default: 0,
})

export const protocolState = atom({
    key: "SET_PROTOCOL_STATE",
    default: String(localStorage.getItem("protocol") || "scatter"),
});

export const selectedAssetsState = atom({
    key: "SET_SELECTED_ASSETS_STATE",
    default: new Map<number, AtomicAsset>(),
})

export const selectedFiltersState = atom({
    key: "SET_SELECTED_FILTERS_STATE",
    default: new Set<string>(),
})

export const trxIdState = atom({
    key: "SET_TRX_ID_STATE",
    default: "",
})

export const isRefreshInventoryState = atom({
    key: "IS_REFRESH_INVENTORY_STATE",
    default: false,
})