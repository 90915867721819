import { useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useLocation } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import {
  ownerState,
  protocolState,
  balanceState,
} from "../atoms/atoms";
import { AccountBalanceWallet } from "@material-ui/icons";
import EOS from '../assets/chains/eos.png';
import * as services from "../services";
import { generateKit } from "../utils/config";
import { Chains, Session, SessionKit } from "@wharfkit/session";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "proxima-soft",
    margin: "auto",
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "25px",
    },
  },

  button: {
    padding: "8px 16px",
    "& .MuiButton-label": {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  dialogCloseButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  dialogContent: {
    paddingBottom: "20px",
  },
  largeButton: {
    borderRadius: "20px",
    margin: "auto",
    backgroundColor: "#FFF",
    padding: "7px 15px",
    color: "rgb(2 28 77)",
    "&:hover": {
      backgroundColor: "#ffffff9e",
    },
  },
}));


const ConnectWallet = () => {
  const classes = useStyles();
  const [ open, setOpen ] = useState(false);
  const { pathname } = useLocation()
  let [ chain ] =  pathname.split("/").slice(1);
  if ( !chain ) chain = "eos";
  const setBalance = useSetRecoilState(balanceState);
  const [ owner, setOwner] = useRecoilState(ownerState);
  const [ protocol, setProtocol ] = useRecoilState(protocolState);
  const [sessionKit, setSessionKit] = useState<SessionKit>();

  const loadBalance = async () => {
    if ( owner ) {
      const amount = await services.chain.get_currency_balance_amount("eosio.token", owner, chain.toUpperCase(), chain);
      setBalance(() => amount);
    }
  }

  // const handleClickOpen = () => {
  //   setOpen(true);
  //   loadBalance();
  // };

  const handleConnectWallet = async () => {
    if ( !sessionKit ) return;
    const { session } = await sessionKit.login();
    setSession(session);
    loadBalance();
  };

  const handleDisconnectWallet = async () => {
    if ( !sessionKit ) return;
    if ( session ) {
      sessionKit.logout();
      setSession(undefined);
    }
  };

  // Restore Wharfkit session
  const [session, setSession] = useState<Session>();
  useEffect(() => {
    const kit = generateKit();
    kit.restore().then((restoredSession) => {
      if (restoredSession) {
        setSession(restoredSession);
        setOwner(restoredSession.actor.toString());
        setProtocol(restoredSession.walletPlugin.metadata.name || "Unknown");
      }
      setSessionKit(kit);
    });
  }, []);

  return (
    <div className={classes.root}>
      {session?.actor ? (
        <Button
          variant="outlined"
          style={{
            borderRadius: "20px",
            margin: "auto",
            textTransform: "none",
            backgroundColor: "#FFF",
            padding: "3px 12px",
            color: "rgb(2 28 77)",
          }}
          startIcon={<img alt={"icon"} src={ EOS } height={25} />}
          onClick={ handleConnectWallet }
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography style={{ margin: "auto 0px", fontSize: "20px", fontWeight: 500 }}>
              { session.actor.toString() }
            </Typography>
          </div>
        </Button>
      ) : (
        <>
          <Button
            variant="outlined"
            className={ classes.largeButton }
            startIcon={ <AccountBalanceWallet /> }
            onClick={ handleConnectWallet }
          >
            Connect
          </Button>
        </>
      )}
    </div>
  );
};

export default ConnectWallet;
