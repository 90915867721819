import * as ipfs from '../utils/ipfs';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    cardImage: {
        width: "auto",
        paddingTop: "16px",
        objectFit: "cover",
        height: "300px",
    },
    assetCardImage: {
        width: "100%",
        paddingTop: "16px",
        objectFit: "contain",
        height: "100px",
    },
}))

export const CardImage = ({ img, name }: {img: string, name: string, }) => {
    const classes = useStyles();
    return (
        <img className={classes.cardImage}
        alt={name}
        onError={(event: any) => event.target.src = ipfs.get_fallback_url(img) }
        src={ ipfs.get_url(img) }
        />
    )
}

export const AssetCardImage = ({img, name}: {img: string, name: string}) => {
    const classes = useStyles();
    return (
        <img className={classes.assetCardImage}
        alt={name}
        onError={(event: any) => event.target.src = ipfs.get_fallback_url(img) }
        src={ ipfs.get_url(img) }
        />
    )
}
