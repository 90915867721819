import axios from 'axios';
import { AtomicCollection, ExtendedBlend, ImmutableSerializedData } from "../types"
import { get_table_rows } from './chain';

export interface GetBlendCollections {
  collection_names: string[];
  atomic_collections: {
    [collection_name: string]: AtomicCollection;
  }
}

export interface GetBlends {
  rows: ExtendedBlend[];
  immutable_serialized_data: {
    [template_id: string]: ImmutableSerializedData;
  },
  atomic_collections: {
    [collection_name: string]: AtomicCollection;
  }
}

export interface NFT {
  collection_name: string;
  template_id: number;
}

export interface Blendlog {
  description: string;
  in_asset_ids: string[];
  in_templates: NFT[];
  out_asset_id: string;
  out_template: NFT;
  owner: string;
  total_burn: number;
  total_mint: number;
}

export interface KeyValue {
  key: string;
  value: any[];
}

export interface Logmint {
  asset_id: string;
  authorized_minter: string;
  backed_tokens: any[];
  collection_name: string;
  immutable_data: any[];
  immutable_template_data: KeyValue[];
  mutable_data: KeyValue[];
  new_asset_owner: string;
  schema_name: string;
  template_id: number;
}

interface GetBlendLog {
  blendlog: Blendlog | null;
  logmint: Logmint | null;
  trx_id: string;
  block_num: number;
  block_time: string;
  last_irreversible_block_num: number;
}

export function getBlendCollections(chain: string, contractName: string) {
  return axios.get<GetBlendCollections>(`https://edge.pomelo.io/api/blend.gems/collections?chain=${chain}&contract=${contractName}&atomic_collections=true`);
}

export function getBlends(chain: string, contractName: string, collectionName: string) {
  return axios.get<GetBlends>(`https://edge.pomelo.io/api/blend.gems/blends?chain=${chain}&contract=${contractName}&collection_name=${collectionName}&immutable_serialized_data=true&atomic_collections=true`);
}

export function getBlendlog(chain: string, trx_id: string, contract: string, action = "blendlog") {
  return axios.get<GetBlendLog>(`https://functions.eosn.io/v2/gems/blend/blendlog?trx_id=${trx_id}&chain=${chain}&contract=${contract}&action=${action}`);
}
