import { Button } from '@material-ui/core';
import AutoAwesomeLogo from '../assets/auto-awesome.svg';
import { useState } from "react";
import { useEffect } from 'react';
import { timeout } from "../utils/utils"

const BlendButton = ({ onClick, start_time, disabled = false } : { onClick: any, start_time: string, disabled: boolean }) => {
    const [ text, setText ] = useState("Blend");
    const [ remaining, setRemaining ] = useState( 0 );

    useEffect(() => {
        const start = new Date( start_time + "Z" ).getTime() / 1000;
        const now = Math.floor(new Date().getTime() / 1000);
        const delta = start - now;
        const h = Math.floor(delta / 3600);
        const m = Math.floor((delta - h * 3600) / 60 );
        const s = Math.floor(delta - m * 60 - h * 3600);
        const clocks = ["🕛","🕒","🕞","🕤"];
        const clock = clocks[remaining % clocks.length];

        if ( delta <= 0 ) setText(`Blend`);
        else {
            if ( h > 1 ) setText( `${h} hours ${clock}` );
            else if ( h ) setText( `${h} hour ${clock}` );
            else if ( m > 1 ) setText( `${m} minutes ${clock}` );
            else if ( m ) setText( `${m} minute ${clock}` );
            else if ( s ) setText( `${s} seconds ${clock}` );
        }
        console.log("BlendButton::setRemaining", { start_time, delta, h, m, s })
        timeout(1000).then( () => {
            if ( delta > 0 ) setRemaining( start - now );
        });
    }, [ remaining ]);

    const cursor = remaining > 0 ? "pointer" : "pointer";

    return (
        <div style={{width:"200px", margin:"auto"}}>
            <Button variant="contained"
                disabled={ remaining > 0 || disabled }
                startIcon={<img alt="blend" src={ AutoAwesomeLogo }/>}
                style={{ backgroundColor: "#4e34db", border:"1px solid #fff", padding: "16px auto", color: "#FFF", borderRadius: "25px", fontFamily:"'proxima-soft', sans-serif", fontWeight:600 }}
                fullWidth
                onClick={ onClick }>{ text }</Button>
        </div>
    );
}

export default BlendButton;