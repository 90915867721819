import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import * as services from "../services";
import BlendBar from "../components/BlendBar";
import MultiRecipeBlend from '../components/MultiRecipeBlend';
import RecipeBlend from '../components/RecipeBlend';
import { useSetRecoilState } from 'recoil';
import { selectedAssetsState } from '../atoms/atoms';
import { Loading } from "../components/Loading";
import { Title } from "./BlendPage";
import { ExtendedBlend, ImmutableSerializedData } from "../types";
import SuccessConfetti from '../components/SuccessConfetti';
import Slide from '@material-ui/core/Slide';

const RecipePage = () => {
    const { chain = "eos", contract = "blend.gems", collection_name, template_id }: any = useParams();
    const [ blend, setBlend] = useState<ExtendedBlend>();
    const [ immutable_serialized_data, setImmutableSerializedData ] = useState<{[template_id: number]: ImmutableSerializedData}>({});
    const [ isLoading, setLoading ] = useState(true);
    const [ blendName, setBlendName ] = useState(template_id);
    const setSelectedAssets = useSetRecoilState(selectedAssetsState);


    useEffect(() => {
      onLoad();
      // return () => {
      //   console.log("UseEffect, end")
      // }
    }, []);

    const onLoad = async () => {
      console.log("RecipePage::onLoad", { chain, contract, collection_name });

      // clear any previous selection
      setSelectedAssets(new Map());

      // get single blend
      const response = await services.gems.getBlends(chain, contract, collection_name);
      if ( response ) {
        setImmutableSerializedData( () => response.data.immutable_serialized_data);

        // select single blend
        for ( const row of response.data.rows ) {
          if ( row.id.template_id === Number(template_id) ) {
            setBlend( () => row );
            setBlendName( () => row.description );
          }
        }
        setLoading(false);
      }
    }

    if ( !blend || isLoading ) return <Loading />

    // Template Name
    // const name = immutable_serialized_data[template_id].name || template_id;
    const isMulti = blend.recipe_ids.length > 1;

    return (
        <div style={{ textAlign:"center", marginBottom: "75px" }}>
          <Slide direction="left" in={true} timeout={250} mountOnEnter unmountOnExit>
            <div>
              <Title name={ blendName } subName={"Blend"} />
              { isMulti ?
                <MultiRecipeBlend recipes={ blend.recipes } immutable_serialized_data={ immutable_serialized_data } /> :
                <RecipeBlend recipe={ blend.recipes[0] } immutable_serialized_data={ immutable_serialized_data } />
              }
            </div>
          </Slide>
          <BlendBar start_time={ blend.start_time } end_time={ blend.end_time }/>
          <SuccessConfetti/>
        </div>
    )
};

export default RecipePage;
