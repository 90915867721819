import axios from 'axios';
import { AtomicAsset } from '../types/atomic'
import { get_table_rows } from "./chain";

interface AtomicResponse<T> {
    success: boolean;
    data: T[];
    query_time: number;
}

export async function getAssets(chain: string, owner: string, optional: { collection_name?: string, template_id?: number | string, revalidate?: boolean } = {}) {
    const params: {[key: string]: string|number} = {
        owner,
        limit: 1000,
        page: 1,
    }
    if ( optional.collection_name ) params["collection_name"] = optional.collection_name;
    if ( optional.template_id ) params["template_id"] = optional.template_id;
    if ( optional.revalidate ) params["revalidate"] = `revalidate_stamp_${+new Date()}`;

    const response = await axios.get<AtomicResponse<AtomicAsset>>(`https://api.${chain}.detroitledger.tech/atomicassets/v1/assets`, { params })
    return response.data.data;
}

interface PossibleValue {
    value: string;
    type: string;
}

export interface CollectionFilter {
    schema_name?: string;
    attribute_name?: string;
    filter_name: string;
    data_type?: string;
    possible_values: PossibleValue[];
    position?: number;
    order?: any;
}

// type Modify<T, R> = Omit<T, keyof R> & R;

// type PureCollectionFilter = Modify<CollectionFilter, {
//     possible_values: string[];
// }>;

export const getCollectionFilters = async ( collection_name: string, chain: string = "eos" ): Promise<CollectionFilter[]> => {
    const rows = await get_table_rows<any>("atomhubtools", "colfilters", { scope: collection_name }, chain );
    return rows.data.rows
      .filter( (filter) => filter.possible_values?.length)
      .map((filter) => {
        return {
            ...filter,
            possible_values: filter.possible_values.map((value: string) => JSON.parse(value))
        }
      })
}

