import { Chains } from "@wharfkit/common"
import { SessionKit } from "@wharfkit/session"
import { WebRenderer } from "@wharfkit/web-renderer"
import { WalletPluginAnchor } from "@wharfkit/wallet-plugin-anchor"
import { WalletPluginCleos } from "@wharfkit/wallet-plugin-cleos"
import { WalletPluginScatter } from "@wharfkit/wallet-plugin-scatter"
import { WalletPluginWombat } from "@wharfkit/wallet-plugin-wombat"
import { WalletPluginTokenPocket } from "@wharfkit/wallet-plugin-tokenpocket"
import { TransactPluginResourceProvider } from "@wharfkit/transact-plugin-resource-provider";
import { TransactPluginCosigner } from "@wharfkit/transact-plugin-cosigner";

const cosigner = new TransactPluginCosigner({
    actor: 'cpu.pomelo',
    permission: 'cosign',
    privateKey: 'PVT_K1_8QwJmjXKkFRSmBzKTUVGHjtpG7WapgvJBt758EAB35oQiYowU', // PUB_K1_78dZPH6sGBCeJ3NTqHECf85QdjXMTz8ZPuLUd7e2Y6AwvZm1wa
})

export function generateKit() {
    return new SessionKit({
        appName: "blend.gems",
        chains: [Chains.EOS],
        ui: new WebRenderer(),
        walletPlugins: [
            new WalletPluginAnchor(),
            new WalletPluginWombat(),
            new WalletPluginTokenPocket(),
            new WalletPluginScatter(),
            new WalletPluginCleos(),
        ],
    }, {
        transactPlugins: [cosigner],
    });
}