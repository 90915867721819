import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    dropdown: {
      backgroundColor: "#3E21DE",
      borderRadius: 25,
      // [theme.breakpoints.down('xs')]: {
      //     width:"275px"
      //   },
      // [theme.breakpoints.up('sm')]: {
      //     paddingRight:"20px",
      //   }
    },
    dropdownImage: {
      height: "auto", paddingRight: "8px",
      [theme.breakpoints.down('xs')]: {
        width: "15px"
      },
      [theme.breakpoints.up('sm')]: {
        width: "25px"
      }
    },
    root: {
      "& .MuiInput-underline:hover": {
        borderBottom: "0px"
      }
    }

  }),
);

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 25,
      position: 'relative',
      //   backgroundColor: theme.palette.background.paper,
      color: "#FFF",
      //   border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      width: "80px",
      backgroundColor: "#3E21DE",
      textAlign: "center",
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 25,
        // borderColor: '#80bdff',
        backgroundColor: "#3E21DE",
        // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);