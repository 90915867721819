import { useEffect, useState} from "react";
import { Typography, Grid, Card, CardActionArea, CardContent, Button } from '@material-ui/core';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import * as services from "../services";
import AutoAwesomeLogo from '../assets/auto-awesome.svg';
import { Loading } from "../components/Loading";
import { ExtendedBlend, ImmutableSerializedData } from "../types";
import { CardImage } from "../components/CardImage";
import Slide from '@material-ui/core/Slide';
import { KeyboardReturn } from "@material-ui/icons";
import { AxiosResponse } from "axios";
import { DEFAULT_IPFS } from "../utils/constants";

function toTitleCase( str: string ) {
    return str.split(" ").map( word => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(" ")
}

export const Title = ({name, subName}: {name: string, subName: string}) => {
  return (
    <Typography style={{ padding:"32px 0px 10px 0px", fontWeight: 500, fontSize: "30px", fontFamily: "'proxima-soft', sans-serif" }}>
      <b>{toTitleCase(name)}</b> <i>{subName}</i>
    </Typography>
  )
}

const BlendButton = ({onClick, disabled = false}: {onClick: any, disabled: boolean}) => {
  return (
    <Button
      disabled={disabled}
      variant="contained"
      startIcon={<img alt="blend" src={AutoAwesomeLogo} />}
      style={{ backgroundColor: "#4e34db", color: "#FFF", borderRadius: "25px", padding: "8px", fontFamily:"'proxima-soft', sans-serif", fontWeight:600 }}
      fullWidth
      onClick={onClick}>Blend
    </Button>
  )
}

type TemplateCardProps = {
  name: string;
  template_id: number;
  img: string;
  description: string;
  isExpired: boolean;
  isRedirect: boolean;
  customCollection: string | undefined;
  isHighlighted: boolean;
}

const TemplateCard = ({ name, template_id, img, description, isExpired, isRedirect, customCollection, isHighlighted }: TemplateCardProps ) => {
  // const classes = useStyles();
  const navigate = useNavigate();
  const { chain = "eos", contract = "blend.gems"} : any = useParams();

  const onClick = () => {
    if (isExpired) {
      return;
    }
    if (isRedirect) {
      window.location.href = 'https://harvest.pomelo.io/'; 
      return;
    }
    if (customCollection) { 
      navigate(`../${chain}/${contract}/${customCollection}/${template_id}`);
      return
    }
    navigate(`${template_id}`);
  }

  return (
    <Slide direction="left" in={true} timeout={450} mountOnEnter unmountOnExit>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} style={{ padding: "6px", textAlign:"center", filter: isExpired ? 'grayscale(1)' : 'grayscale(0)' }} key={template_id}>
        <Card style={{ borderRadius: "25px", background: isHighlighted ? '#4a32d42e' : 'white' }}>
          <CardActionArea style={{cursor: isExpired ? 'not-allowed' : 'pointer'}} onClick={onClick}>
            <CardImage img={img} name={name} />
            <CardContent>
              <Typography variant="h5" component="div" style={{ padding: "0px 8px 16px 8px", fontFamily:"'proxima-soft', sans-serif" }}>{description}</Typography>
              <BlendButton disabled={isExpired} onClick={onClick} />
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Slide>
  )
}

const BlendPage = () => {
  const { chain = "eos", contract = "blend.gems", collection_name } : any = useParams();
  const [ blends, setBlends] = useState<ExtendedBlend[]>([]);
  const [ immutable_serialized_data, setImmutableSerializedData] = useState<{[template_id: number]: ImmutableSerializedData}>({});
  // const [ atomic_collections, setAtomicCollections ] = useState<{[collection_name: string]: AtomicCollection}>({});
  const [ isLoading, setLoading ] = useState(true);
  const [searchParams] = useSearchParams();
  
  const blendsQuery: string[] | undefined = searchParams.get('blends')?.split(',');
  const oldPomeloNFTIds = [1653, 1797];

  useEffect(() => {
    onLoad();
  }, [])

  const onLoad = async () => {
    console.log("BlendPage::onLoad", { chain, contract, collection_name });
    const response = await services.gems.getBlends(chain, contract, collection_name);
    let gemsResponse: AxiosResponse<services.gems.GetBlends> | null = null;
    if (collection_name === 'pomelo') {
      gemsResponse = await services.gems.getBlends(chain, contract, '.gems');
    }
    if ( response ) {
      setImmutableSerializedData( () => Object.assign(response.data.immutable_serialized_data, gemsResponse?.data?.immutable_serialized_data));
      const data = gemsResponse?.data?.rows || [];
      if (data.length) {
        setBlends( () => [...data.filter((row) => {
          row.custom_collection = '.gems';
          return oldPomeloNFTIds.includes(row.id.template_id)
        }), ...response.data.rows]);
      } else {
        setBlends( () => response.data.rows);
      }
      setLoading(false);
    }
  }

  if ( isLoading ) return <Loading />

  return (
    <Slide direction="left" in={true} timeout={250} mountOnEnter unmountOnExit>
    <div style={{ textAlign:"center" }}>
      <Title name={ collection_name } subName={"Collection"} />
      <Grid container style={{ display: "flex", flexDirection: "row", justifyContent: "center", padding: "8px 8px 150px 8px" }} >
      {
        blends.sort((a,b) => +b.id.template_id - +a.id.template_id).map(blend => {
          const template_id = blend.id.template_id;
          const data = immutable_serialized_data[template_id];
          const name = data.name;
          const img = data.img || data.backimg || data.cardimg || DEFAULT_IPFS;
          const { description } = blend;
          let isExpired = false;
          if ( blend.end_time ) isExpired = +new Date(blend.end_time) < +new Date();
          const customCollection = blend?.custom_collection;

          return <TemplateCard isHighlighted={!!blendsQuery?.includes(template_id.toString())} isRedirect={blend.id.template_id === 6845} isExpired={isExpired} customCollection={customCollection} key={template_id} name={name} template_id={template_id} img={img} description={description} />
        })
      }
      </Grid>
    </div>
</Slide>
  )
}
export default BlendPage;
