import axios from 'axios';
import { EOSIO_RPCS } from "../utils/constants";

export async function get_currency_balance(code: string, account: string, symbol: string, chain: string ) {
    const url = `${EOSIO_RPCS[chain]}/v1/chain/get_currency_balance`;
    return axios.post<string[]>(url, {code, account, symbol})
}

export async function get_currency_balance_amount(code: string, account: string, symbol: string, chain: string ) {
    const balance = await get_currency_balance( code, account, symbol, chain );
    if ( balance.data.length ) return Number(balance.data[0].split(" ")[0]);
    return 0;
}

interface GetTableRowsOptional {
    lower_bound?: string;
    upper_bound?: string;
    key_type?: string;
    limit?: number;
    index_position?: number;
    reverse?: boolean;
    scope?: string;
}

export async function get_table_rows<T>( code: string, table: string, optional: GetTableRowsOptional = {}, chain: string ) {
    const data = Object.assign({
        json: true,
        code,
        scope: code,
        table,
        limit: 500,
    }, optional );
    const url = `${EOSIO_RPCS[chain]}/v1/chain/get_table_rows`;
    return axios.post<{rows: T[], more: boolean}>(url, data );
}

// get_table_rows<{collection_names: string[]}>("blend.gems", "collections").then(a => {
//     console.log(a.data.rows[0].collection_names)
// });

// get_currency_balance_amount("eosio.token", "eosio.null", "EOS", "eos").then(a => console.log(a))