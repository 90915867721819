// import React, { FC } from 'react';
import { Link } from 'react-router-dom';


const Page404 = () => {
  return(
    <section className="page-404">
      <div className="container">
        <h1>404</h1>
        <p>Page Does Not Exist</p>
        <p><Link to="/">Return Home</Link></p>
      </div>
    </section>
  );
}

export default Page404;