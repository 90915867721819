import { List, Typography, Grid, IconButton, Tooltip, Collapse } from '@material-ui/core';
import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import {
  ownerState,
  selectedAssetsState,
  isRefreshInventoryState,
  selectedFiltersState,
} from '../atoms/atoms';
import { AtomicAsset } from '../types/atomic';
import * as services from '../services';
import InventoryAsset from './InventoryAsset';
import FiltersList, { VisibilityFilters } from './FiltersList';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Refresh, SelectAll } from '@material-ui/icons';
import { groupFilters } from '../utils/filters';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: '#FFFFFF',
    padding: '0px',
    borderRadius: '24px',
  },
  list: {
    minHeight: '150px',
    maxHeight: '350px',
    overflow: 'auto',
    borderRadius: '25px',
    padding: '0px',
  },
}));

type InventoryProps = {
  collection_name: string;
  schema_name: string;
  template_id: number;
  quantity: number;
  optional?: boolean;
};

const InventoryList = ({ collection_name, schema_name, template_id, quantity, optional }: InventoryProps) => {
  const classes = useStyles();
  const visibleAssets = new Set<number>();
  const [assets, setAssets] = useState<AtomicAsset[]>([]);
  const [assetsList, setAssetsList] = useState<AtomicAsset[]>([]);
  const { chain, contract } = useParams();
  if (!chain) throw new Error('[chain] is required');
  const [isRefreshInventory, setIsRefreshInventory] = useRecoilState<boolean>(isRefreshInventoryState);
  const [selectedAssets, setSelectedAssets] = useRecoilState<Map<number, AtomicAsset>>(selectedAssetsState);
  const [tooltipAttributes, setTooltipAttributes] = useState<Set<string>>(new Set());
  const [isAutoSelectEnabled, setIsAutoSelectEnabled] = useState<boolean>(true);

  let [count, setCount] = useState(0);
  let [total, setTotal] = useState(0);
  const owner = useRecoilValue(ownerState);
  const [selectedFilters, setSelectedFilters] = useRecoilState(selectedFiltersState);
  const assetsListRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (!assetsList?.length) {
      return;
    }
    const filters = groupFilters(template_id, selectedFilters);
    const nativeFilters = Array.from(filters.keys()).filter((filter_name) => filter_name !== 'is_custom');
    const customFilterArray = Array.from(filters.keys()).filter((filter_name) => filter_name === 'is_custom');

    const selectedAssetsIds = Array.from(selectedAssets.keys());
    assetsList.forEach((asset) => {
      if (selectedAssetsIds.includes(+asset.asset_id)) {
        selectedAssets.delete(+asset.asset_id);
      }
    });
    setSelectedAssets(new Map(selectedAssets));

    if (!customFilterArray.length) {
      const filteredAssets = assetsList.filter((asset) => {
        return nativeFilters.every((filter_name) => {
          const nativeFilterValues = filters.get(filter_name) || new Set();
          return nativeFilterValues.has(asset.data[filter_name].toString());
        });
      });
      setAssets(filteredAssets);
      return;
    }
    if (customFilterArray.length > 1) {
      return;
    }
    for (const filter_name of customFilterArray) {
      const values = filters.get(filter_name) || new Set();
      switch (Array.from(values)[0]) {
        case VisibilityFilters.UNIQUES:
          const uniqNames = Array.from(new Set(assetsList.map((asset) => asset.name)));
          let uniqAssets: AtomicAsset[] = [];
          assetsList.forEach((asset) => {
            if (!nativeFilters.length) {
              if (uniqNames.includes(asset.name) && !uniqAssets.some((uAsset) => uAsset.name === asset.name)) {
                uniqAssets.push(asset);
              }
            } else {
              let isPassFilter = true;

              const isAlreadyThere = uniqAssets.some((uAssset) => {
                return (
                  uAssset.name === asset.name &&
                  nativeFilters.every((filter_name) => {
                    const nativeFilterValues = filters.get(filter_name) || new Set();
                    return (
                      uAssset.data[filter_name] === asset.data[filter_name] &&
                      nativeFilterValues.has(uAssset.data[filter_name].toString())
                    );
                  })
                );
              });

              nativeFilters.forEach((filter_name) => {
                const nativeFilterValues = filters.get(filter_name) || new Set();
                if (!nativeFilterValues.has(asset.data[filter_name].toString())) {
                  isPassFilter = false;
                }
              });

              if (isPassFilter && !isAlreadyThere) {
                uniqAssets.push(asset);
              }
            }
          });
          setAssets(uniqAssets);
          return;
        case VisibilityFilters.DUPLICATES:
          let duplicateAssets: AtomicAsset[] = [];
          if (!nativeFilters.length) {
            assetsList.forEach((asset, index) => {
              if (!!(assetsList.findIndex((item2) => asset.name === item2.name) !== index)) {
                duplicateAssets.push(asset);
              }
            });
          } else {
            const filteredAssets = assetsList.filter((asset) => {
              return nativeFilters.every((filter_name) => {
                const nativeFilterValues = filters.get(filter_name) || new Set();
                return nativeFilterValues.has(asset.data[filter_name].toString());
              });
            });

            filteredAssets.forEach((asset, index) => {
              if (
                !!(
                  filteredAssets.findIndex(
                    (item2) =>
                      asset.name === item2.name &&
                      nativeFilters.every((filter_name) => asset.data[filter_name] === item2.data[filter_name])
                  ) !== index
                )
              ) {
                duplicateAssets.push(asset);
              }
            });
          }

          setAssets(duplicateAssets);
          return;
      }
    }
  }, [selectedFilters, assetsList]);

  useEffect(() => {
    if (!owner) return;
    onLoad();
  }, [owner, template_id]);

  useEffect(() => {
    if (isRefreshInventory) {
      setAssets([]);
      setAssetsList([]);
      setCount(0);
      selectedAssets.clear();
      setSelectedAssets(new Map(selectedAssets));
      onLoad(true);
      setIsRefreshInventory(false);
    }
  }, [isRefreshInventory]);

  const onLoad = async (isRefresh: boolean = false) => {
    console.log('Inventory::onLoad', { owner, template_id });
    if (owner) {
      const data = await services.atomic.getAssets(chain, owner, { template_id, revalidate: isRefresh });
      setTotal(data.length);
      setAssets(data);
      setAssetsList(data);
    }
  };

  useEffect(() => {
    if (!assets?.length) {
      return;
    }
    preselectAssets();
    setTotal(assets?.length);
  }, [assets, isAutoSelectEnabled]);

  const preselectAssets = () => {
    if (!isAutoSelectEnabled) {
      return;
    }
    let count = 0;
    assets.forEach((asset, index) => {
      if (index < quantity) {
        selectedAssets.set(+asset.asset_id, asset);
        count++;
      }
    });
    setCount(count);
    setSelectedAssets(new Map(selectedAssets));
  };

  const selectAsset = (asset: AtomicAsset) => {
    const asset_id = Number(asset.asset_id);

    if (selectedAssets.has(asset_id)) {
      count -= 1;
      selectedAssets.delete(asset_id);
    } else {
      if (count >= quantity) return false;
      count += 1;
      selectedAssets.set(asset_id, asset);
    }
    setCount(count);
    setSelectedAssets(new Map(selectedAssets));
    return true;
  };

  const memoList = assets.map((asset: AtomicAsset, index: number) => {
    return (
      <InventoryAsset
        selectedAssets={selectedAssets}
        tooltipAttributes={tooltipAttributes}
        isAutoSelectEnabled={isAutoSelectEnabled}
        key={index}
        asset={asset}
        optional={optional}
        selectAsset={selectAsset}
      />
    );
  });

  return (
    <Grid item xs={12}>
      <QuantitySelected
        isAutoSelectEnabled={isAutoSelectEnabled}
        setIsAutoSelectEnabled={setIsAutoSelectEnabled}
        collection_name={collection_name}
        schema_name={schema_name}
        count={count}
        template_id={template_id}
        quantity={quantity}
        total={total}
        optional={optional}
        setTooltipAttributes={setTooltipAttributes}
      />
      <List ref={assetsListRef} dense className={classes.list}>
        {memoList}
      </List>
    </Grid>
  );
};

interface QuantitySelectedProps {
  collection_name: string;
  schema_name: string;
  template_id: number;
  count: number;
  quantity: number;
  total: number;
  optional?: boolean;
  setTooltipAttributes: (value: Set<string>) => void;
  isAutoSelectEnabled: boolean,
  setIsAutoSelectEnabled: (value: boolean) => void;
}

const QuantitySelected = ({
  collection_name,
  schema_name,
  template_id,
  count,
  quantity,
  optional,
  total,
  setTooltipAttributes,
  isAutoSelectEnabled,
  setIsAutoSelectEnabled
}: QuantitySelectedProps) => {
  const [open, setOpen] = useState(false);
  const [isRefreshInventory, setIsRefreshInventory] = useRecoilState(isRefreshInventoryState);

  const handleClickToggleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClickToggleRefresh = () => {
    setIsRefreshInventory(true);
  };

  let header = `${count} of ${quantity} selected`;
  if (total) header += ` (x${total}) `;
  if (optional) header += ' *Substitute';

  return (
    <>
      <div
        style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', padding: '0px 8px 0px 8px' }}
      >
        <Typography style={{ padding: '16px', fontFamily: "'proxima-soft', sans-serif", fontWeight: 600 }}>
          {header}
        </Typography>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Tooltip title='Auto select assets' aria-label='refresh'>
            <IconButton style={{padding: '4px'}} onClick={() => {setIsAutoSelectEnabled(!isAutoSelectEnabled)}}>
              <SelectAll  color={isAutoSelectEnabled ? 'primary' : 'inherit'} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Refresh' aria-label='refresh'>
            <IconButton style={{padding: '4px'}} onClick={handleClickToggleRefresh}>
              <Refresh />
            </IconButton>
          </Tooltip>
          <Tooltip title='Filters' aria-label='filters'>
            <IconButton style={{padding: '4px'}} onClick={handleClickToggleOpen}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <Collapse in={open}>
        <FiltersList
          setTooltipAttributes={setTooltipAttributes}
          collection_name={collection_name}
          schema_name={schema_name}
          template_id={template_id}
        />
      </Collapse>
    </>
  );
};

export default InventoryList;
