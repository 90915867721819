import { Card, Grid } from '@material-ui/core';
import { sumIngredients } from "../utils/recipes";
import InventoryList from './InventoryList';
import RecipeBadge from './RecipeBadge';
import { ImmutableSerializedData, NFT } from "../types";
import Slide from '@material-ui/core/Slide';

interface RecipeBlendParam {
  recipe: NFT[];
  immutable_serialized_data: {[template_id: number]: ImmutableSerializedData}
}

export const RecipeBlend = ({ recipe, immutable_serialized_data} : RecipeBlendParam ) => {
  const stackedIngredients = sumIngredients(recipe);

  return (
    <Grid container style={{ display:"flex", justifyContent:"center" }}>
      { Array.from(stackedIngredients.entries()).map(([key, quantity]) => {
          const collection_name = key.split(",")[0];
          const template_id = Number(key.split(",")[1]);
          const schema_name = key.split(",")[2];
          return (
            <Slide key={template_id} direction="left" in={true} timeout={450} mountOnEnter unmountOnExit>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3} style={{ padding: "6px", display: "flex", flexDirection:"row", justifyContent:"center" }}>
                  <Card style={{width:"100%", borderRadius:"24px", padding:"8px 0px 16px 0px"}}>
                    <Grid container style={{ display:"flex", flexDirection:"column", justifyContent:"center" }}>
                      <Grid item xs={12}>
                        <RecipeBadge template_id={ template_id } quantity={ quantity } immutable_serialized_data={ immutable_serialized_data } />
                      </Grid>
                      <Grid item xs={12}>
                        <InventoryList template_id={ template_id } quantity={ quantity } collection_name={ collection_name } schema_name={schema_name} />
                      </Grid>
                    </Grid>
                  </Card>
              </Grid>
            </Slide>
          )
        })
      }
    </Grid>
  )
};

export default RecipeBlend;
