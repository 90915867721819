export function groupFilters( template_id: number, filters: Set<string> ) {
  const group = new Map<string, Set<string>>();
  for ( const filter of filters ) {
    // filter //=> "4574,rarity,Rare"
    const [ filter_template_id, filter_name, value ] = filter.split(",")
    if ( Number(filter_template_id) !== template_id ) continue;
    const values = group.get( filter_name ) || new Set();
    values.add( value );
    group.set( filter_name, values );
  }
  return group
}

// (() => {
//   const assets = [
//     { template_id: 4574, data: { rarity: "Rare", color: "Amber" } },
//     { template_id: 4574, data: { rarity: "Common", color: "Mint" } },
//   ]

//   const filters = new Set([
//     "4574,rarity,Rare",
//     "4574,color,Amber",
//     "4574,rarity,Common",
//     "4572,mana,0",
//   ]);

//   const group = groupFilters(4574, filters);
//   console.log(group)
// })();
