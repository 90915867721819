import { AnyAction, Session } from "@wharfkit/session";

export function blend(from: Session, contract: string, asset_ids: number[], collection_name: string, template_id: number | string ): AnyAction {
    if ( !collection_name ) throw new Error("[collection_name] is required");
    if ( !template_id ) throw new Error("[template_id] is required");

    const memo = `${collection_name}:${template_id}`;
    return atomicTransfer(from, contract, asset_ids, memo );
}

export function atomicTransfer(from: Session, to: string, asset_ids: number[], memo: string): AnyAction {
    if ( !from ) throw new Error("[from] is required");
    if ( !to ) throw new Error("[to] is required");
    if ( !asset_ids.length ) throw new Error("[asset_ids] is empty");

    return {
        account: 'atomicassets',
        name: 'transfer',
        authorization: [ from.permissionLevel],
        data: {
            from: from.actor,
            to,
            asset_ids,
            memo
        },
    }
}