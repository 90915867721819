import { useState, useEffect } from 'react';
import { Chip } from '@material-ui/core';
import { useRecoilState } from 'recoil';
import { selectedFiltersState } from '../atoms/atoms';
import { groupFilters } from "../utils/filters";
import { useSearchParams } from 'react-router-dom';

interface FilterProps {
    template_id: number;
    attribute_name: string;
    schema_name: string;
    value: string;
}

const Filter = ({ template_id, schema_name, attribute_name, value }: FilterProps) => {
    const [ checked, setChecked ] = useState<boolean>(false);
    const [ selectedFilters, setSelectedFilters ] = useRecoilState(selectedFiltersState);
    const [ isCustomFilterSelected, setIsCustomFilterSelected ] = useState<boolean>(false);
    const [ isCustomFilter, setIsCustomFilter ] = useState<boolean>(false);
    const [ searchParams, setSearchParams ] = useSearchParams();

    useEffect(() => {
        setIsCustomFilter(attribute_name === 'is_custom');
    }, [])

    useEffect(() => {
        const filters = groupFilters( template_id, selectedFilters );
        const customFilterArray = Array.from(filters.keys()).filter((filter_name) => filter_name === 'is_custom');
        setIsCustomFilterSelected(!!customFilterArray.length);
        const key = [template_id, attribute_name, value].join(',');
        setChecked(selectedFilters.has(key))
    }, [selectedFilters])

    const handleToggle = () => {
        if (!checked && isCustomFilterSelected && isCustomFilter) {
            return;
        }
        console.log("Filter::handleToggle", { schema_name, value });
        const key = [template_id, attribute_name, value].join(',');
        if ( selectedFilters.has( key ) ) selectedFilters.delete( key );
        else selectedFilters.add( key );

        const preselectedFilters: {key: string, value: string}[] | undefined = searchParams.get('filters')
            ?.split(',')
            ?.map(filter => {
                return {
                    key: filter.split(':')[0],
                    value: filter.split(':')[1],
                }
            })
            ?.filter((filter) => filter.key && filter.value);

        const matchedFilter = preselectedFilters?.find(filter => filter.key === attribute_name && filter.value === value);
        if (matchedFilter) {
            const newFilters = preselectedFilters?.filter((filter) => {
                return (filter.key.toLowerCase() !== attribute_name.toLowerCase()) || (filter.value.toLowerCase() !== value.toLowerCase());
            });
            const queryPayload = {filters: newFilters?.length ? `${newFilters?.map((filter) => (`${filter.key}:${filter.value}`))}` : ``};
            setSearchParams(queryPayload);
        } else {
            const queryPayload = {filters: preselectedFilters?.length ? `${preselectedFilters?.map((filter) => (`${filter.key}:${filter.value}`))},${attribute_name}:${value}` : `${attribute_name}:${value}`};
            setSearchParams(queryPayload);
        }
        setSelectedFilters( new Set(Array.from(selectedFilters)) );
        console.log("InventoryAsset::handleToggle:", { schema_name, value, checked, selectedFilters });
    }
    const disabled = false;
    const color = checked ? "primary" : "default";

    return <Chip color={ color } disabled={ disabled || (!checked && isCustomFilterSelected && isCustomFilter) } label={ value } onClick={ handleToggle } style={{
        margin: "3px", 
        background: isCustomFilter && !checked ? 'lightblue' : ''
    }} />
}

export default Filter;